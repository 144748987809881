import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import styles from "../../Input/style.module.css";
import { GoogleSignup, RootReducer } from "../../../interfaces/reducer";
import "./style.css";
import { Google, neuDaddyLogo } from "../../../assets/images";
import { ACTIONS } from "../../../redux/actions";
import { LoginSchema } from "../../../schemas";
import Input from "../../Input";
import InputStyles from "../../Input/style.module.css";
import { AppDispatch } from "../../../redux/store";
import { LoginPayload } from "../../../interfaces/auth";
import { AuthAction, UserAction } from "../../../redux/thunk";
import { signInWithGooglePopup } from "../../../Firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../constants/routes";
import VerifyEmail from "../Signup/Steps/VerifyEmail";
import { VerifyEmailIn } from "../../../interfaces/user";
import Loader from "../../Loader/Spinner";

interface Props extends MapDispatchToProps {}

const LoginModal = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const phoneNumberRef = useRef<any>(null);

  const { loginModal, resetForm } = useSelector(
    (state: RootReducer) => state.auth
  );
  const [isPhoneNumber, setPhoneNumberInput] = useState<boolean>(false);
  const [recaptchaError, setRecaptchaError] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [loader, setLoader] = useState(false);

  interface FormValues {
    emailOrPhone: string;
    password: string;
    recaptcha: string | null;
  }

  const handleLoginModal = () => {
    dispatch({ type: ACTIONS.SET_LOGIN_MODAL, status: false });
    loginFormik.resetForm();
  };

  const handleSignUp = () => {
    dispatch({ type: ACTIONS.SET_SIGNUP_MODAL, status: true });
    dispatch({ type: ACTIONS.SET_LOGIN_MODAL, status: false, resetForm: true });
  };

  const logGoogleUser = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    try {
      const response: any = await signInWithGooglePopup();
      dispatch({ type: ACTIONS.SET_GOOGLE_LOGIN, status: true });
      const resp = await props.existingGoogleProfile({
        id: response.user.uid,
        email: response.user.email as string,
        name: response.user.displayName as string,
        google_id: JSON.parse(response?._tokenResponse?.rawUserInfo)?.id,
      });
      if (resp.success) {
        navigate(ROUTES.MEMBERS);
      }
      dispatch({
        type: ACTIONS.SET_LOGIN_MODAL,
        status: false,
        resetForm: true,
      });
    } catch (error) {}
  };

  const loginFormik = useFormik<FormValues>({
    initialValues: { emailOrPhone: "", password: "", recaptcha: null },
    validationSchema: LoginSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const captchaValue = recaptchaRef.current?.getValue();
      if (!captchaValue) {
        setRecaptchaError(true);
        return;
      }
      setRecaptchaError(false);
      const numberRegex = /^\+?[0-9]{1,4}?\s?[0-9\s]{10,}$/; // Updated regex to allow spaces within the phone number
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (!numberRegex.test(values.emailOrPhone)) {
        // Check if it's a valid email if it's not a phone number
        if (!emailRegex.test(values.emailOrPhone)) {
          loginFormik.setFieldError("emailOrPhone", "Invalid Email");
        } else {
          loginFormik.setFieldError("emailOrPhone", "Invalid Phone Number");
        }
      }

      const payload: LoginPayload = {
        ...values,
        emailOrPhoneType: numberRegex.test(values.emailOrPhone)
          ? "phone"
          : "email",
        device_type: "android", // Static values to be removed
        device_token: "string", // Static values to be removed
      };

      if (isValidPhoneNumber(values.emailOrPhone)) {
        const parsedNumber = parsePhoneNumber(values.emailOrPhone);
        payload.emailOrPhone = parsedNumber?.nationalNumber as string;
        payload.country_code = parsedNumber?.countryCallingCode as string;
      } else if (numberRegex.test(values.emailOrPhone)) {
        loginFormik.setFieldError("emailOrPhone", "Invalid Phone number");
        return;
      }
      setLoader(true);
      const resp = await props.login(payload);
      setLoader(false);
      if (resp?.id) {
        navigate(ROUTES.MEMBERS);
      } else if (Object.keys(resp)?.length > 0 && !resp?.email_verified) {
        setShowVerificationModal(true);
      }
    },
  });

  const handleModalConfirm = async () => {
    setLoader(true);
    await props.verifyEmail({ email: loginFormik?.values?.emailOrPhone });
    setLoader(false);
    setShowVerificationModal(false);
  };

  const handleModalHide = () => {
    setShowVerificationModal(false);
  };

  useEffect(() => {
    if (
      isPhoneNumber &&
      phoneNumberRef &&
      phoneNumberRef.current &&
      phoneNumberRef.current.focus
    ) {
      phoneNumberRef.current.focus();
    }
  }, [isPhoneNumber]);

  useEffect(() => {
    if (!loginFormik.values.emailOrPhone) {
      setPhoneNumberInput(false);
    }
  }, [loginFormik.values.emailOrPhone]);

  useEffect(() => {
    if (resetForm) {
      loginFormik.resetForm();
    }
  }, [resetForm]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const forgotPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    dispatch({ type: ACTIONS.SET_LOGIN_MODAL, status: false });
    dispatch({ type: ACTIONS.FORGOT_PSWD_MODAL, status: true });
  };

  return (
    <div className={`modal login-modal ${loginModal ? "d-block" : ""}`}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        {loader && <Loader />}
        <div className="modal-content Edit_your_info">
          <div className="modal-header">
            <div className="logo-modal">
              <img className="me-2" src={neuDaddyLogo} alt="" />
              <FontAwesomeIcon
                className="cursor-pointer close"
                size="lg"
                icon={faClose}
                onClick={handleLoginModal}
              />
            </div>
          </div>

          <div className="modal-body px-0 pb-0">
            <div className="form-sec">
              <form onSubmit={loginFormik.handleSubmit}>
                <div className="row">
                  <div className="Choose_Username about_useredit login-form ">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label>Email or Mobile Number</label>
                          {isPhoneNumber ? (
                            <Fragment>
                              <PhoneInput
                                international
                                placeholder="Enter phone number"
                                onChange={(value) => {
                                  loginFormik.setFieldValue(
                                    "emailOrPhone",
                                    value
                                  );
                                }}
                                className="phone-number"
                                ref={phoneNumberRef}
                                defaultCountry="IN"
                                value={loginFormik.values.emailOrPhone as any}
                              />
                              {loginFormik.touched.emailOrPhone &&
                              loginFormik.errors.emailOrPhone ? (
                                <div className={InputStyles.error}>
                                  {loginFormik.errors.emailOrPhone}
                                </div>
                              ) : null}
                            </Fragment>
                          ) : (
                            <Input
                              type="text"
                              name="emailOrPhone"
                              handleChange={(event) => {
                                const {
                                  target: { value },
                                } = event;
                                const numberRegex = /^[0-9]/;
                                if (numberRegex.test(value)) {
                                  setPhoneNumberInput(true);
                                  // Trigger a forced re-render
                                  setTimeout(
                                    () =>
                                      loginFormik.setFieldValue(
                                        "emailOrPhone",
                                        `+91${value}`
                                      ),
                                    0
                                  );
                                } else {
                                  loginFormik.setFieldValue(
                                    "emailOrPhone",
                                    value
                                  );
                                }
                              }}
                              placeHolder="Enter email address or phone number"
                              value={loginFormik.values.emailOrPhone}
                              error={
                                loginFormik.touched.emailOrPhone &&
                                loginFormik.errors.emailOrPhone
                                  ? loginFormik.errors.emailOrPhone
                                  : ""
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div>
                          <div className="d-flex justify-content-between">
                            <label>Password</label>
                          </div>
                          <div className="position-relative cursor-pointer">
                            <Input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              placeHolder="Enter your password"
                              handleChange={loginFormik.handleChange}
                              value={loginFormik.values.password}
                              error={
                                loginFormik.touched.password &&
                                loginFormik.errors.password
                                  ? loginFormik.errors.password
                                  : ""
                              }
                            />
                            <span
                              className="position-absolute top-50  translate-middle-y password-toggle-btn"
                              style={{ right: "25px" }}
                              onClick={togglePasswordVisibility}
                            >
                              <FontAwesomeIcon
                                icon={!showPassword ? faEyeSlash : faEye}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12  mt-4">
                        <div className="">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_SITE_KEY_RECAPTCHA!}
                          />
                          {recaptchaError ? (
                            <div className={styles.error}>
                              {"Please complete the reCAPTCHA verification."}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <button type="submit">Continue</button>
                        </div>
                      </div>
                    </div>
                    <div className="or-line">
                      <span>OR</span>
                      <hr />
                    </div>
                    <div className="goole-social">
                      <button
                        onClick={logGoogleUser}
                        className="google-cont google_b"
                      >
                        <img src={Google} alt="" />
                        Continue with Google
                      </button>
                    </div>
                    <div className="goole-social">
                      <button
                        onClick={forgotPassword}
                        className="google-cont google_b"
                      >
                        Forgot Password
                      </button>
                    </div>
                    <div className="d-flex justify-content-center">
                      <span onClick={handleSignUp} className="cursor-pointer">
                        Don't Have An Account?
                        <a href="#" className="sign-u">
                          Sign Up
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <VerifyEmail
        show={showVerificationModal}
        onHide={handleModalHide}
        onConfirm={handleModalConfirm}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  login: (payload: LoginPayload) => dispatch(AuthAction.login(payload)),
  verifyEmail: (payload: VerifyEmailIn) =>
    dispatch(UserAction.verifyEmail(payload)),
  existingGoogleProfile: (payload: GoogleSignup) =>
    dispatch(AuthAction.existingGoogleProfile(payload)),
});

interface MapDispatchToProps {
  login: (payload: LoginPayload) => any;
  verifyEmail: (payload: VerifyEmailIn) => any;
  existingGoogleProfile: (payload: GoogleSignup) => any;
}

export default connect(null, mapDispatchToProps)(LoginModal);
